<template>
  <div class="section-card-box" @click="handleClickItem">
    <div class="img-box">
      <img :src="other.NewsTitlePic || data.NewsTitlePic" style="min-height: 230px">
      <img v-show="Object.keys(other).length > 0 ? !!other.VideoLink : !!data.VideoLink" src="@/assets/image/btcinfo/video.png">
    </div>
    <div class="text-box">
      <p style="font-size: 12px; font-weight: bold;">{{ other.NewsDateTime || data.NewsDateTime }}</p>
      <p style="height: 57px;font-weight: bold;font-size: 16px">{{ other.NewsTitle || data.NewsTitle }}</p>
      <p style="font-size: 12px; height: 54px;">{{ other.NewsSummary || data.NewsSummary }}</p>
      <div class="bottom-box">
        <div class="card-more">
          <span class="hand" style="font-size: 12px;">详情</span>
          <div style="margin-left: 10px;">></div>
        </div>
        <div class="card-flag">
          <img v-for="(item, index) in langs" :key="index" :src="item.icon" :alt="item.lang" class="hand" @mouseenter="handleChangeLang(item.lang)">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import commonmix from '@/utils/commonmix'

export default {
  mixins: [commonmix],
  name: 'CnBTCInfoSectionCard',
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    isEnExpire: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      other: {},
      langIcon: [
        { lang: 'en', icon: require('@/assets/image/btcinfo/american.png') },
        { lang: 'spanish', icon: require('@/assets/image/btcinfo/spanish.png') },
        { lang: 'portuguese', icon: require('@/assets/image/btcinfo/portuguese.png') }
      ],
      timeout: null,
      typeList: [
        { label: '全部', value: 'btcinfo' },
        { label: '必孚牛肉', value: '_beefnewsandevents' },
        { label: '必孚猪肉', value: '_porknews' },
        { label: '必孚多肉', value: '_beefpicked' }
      ]
    }
  },
  computed: {
    langs () {
      const newArr = [...this.data.Langs]
      if (newArr.length !== 0) {
        newArr.unshift('en')
      }
      return this.langIcon.filter(v => {
        return newArr.includes(v.lang)
      })
    },
    articles () {
      return this.data.Recommendation
    },
    type () {
      const type1 = this.typeList.map(v => {
        return v.value
      })
      if (type1.includes(this.$route.query.type)) {
        return 'btcinfo'
      }
      return 'btcanalysis'
    }
  },
  methods: {
    handleClickItem () {
      if (this.$store.getters.token) {
        if (this.isEnExpire) {
          this.$store.commit('SET_NEWSID', this.data.NewsID)
          // 跳转新界面
          // this.$router.push(`/BTCInfo/Article/${this.other.NewsID || this.data.NewsID}`)
          const path = `/cn/BTCInfo/Article/${this.other.NewsID || this.data.NewsID}`
          const routeData = this.$router.resolve({ path: path, query: { rid: this.data.NewsID } })
          const open = window.open(routeData.href, '_blank')
          if (open === null || typeof (open) === 'undefined') {
            this.$router.push(path)
          }
        } else {
          if (this.type === 'btcinfo') {
            this.$store.commit('SET_NEWSID', this.data.NewsID)
            // 跳转新界面
            // this.$router.push(`/BTCInfo/Article/${this.other.NewsID || this.data.NewsID}`)
            const path = `/cn/BTCInfo/ArticleFree/${this.other.NewsID || this.data.NewsID}`
            const routeData = this.$router.resolve({ path: path, query: { rid: this.data.NewsID } })
            const open = window.open(routeData.href, '_blank')
            if (open === null || typeof (open) === 'undefined') {
              this.$router.push(path)
            }
          } else {
            this.openCnMembershipModal()
          }
        }
      } else {
        this.openCnLoginModal()
      }
    },
    handleChangeLang (lang) {
      if (lang === 'en') {
        this.other = {}
      } else {
        this.other = this.articles.filter(v => {
          return v.NewsLanguageCode === lang
        })[0]
      }
    }
  }
}
</script>

<style lang="scss" scoped>
$radius: 14px;

.section-card-box {
  width: 305px;
  display: flex;
  flex-direction: column;
  border-radius: $radius;
  box-shadow: 2px 2px 5px 2px #e0e0e0;
}
.section-card-box:not(:first-child) {
  margin-left: 10px;
}
.section-card-box > .img-box {
  position: relative;
}
.section-card-box > .img-box > img:first-child {
  width: 100%;
  border-radius: $radius $radius 0 0;
}
.section-card-box > .img-box > img:last-child {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
}

.section-card-box > .text-box {
  display: flex;
  flex-direction: column;
  padding: 8px;
}

.bottom-box {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.card-more {
  display: flex;
  align-items: center;
  color: #df232a;
}
.card-flag {
  display: flex;
  align-items: center;
}
.card-flag > img {
  height: 20px;
  margin-left: 10px;
}
// .card-flag > img:last-child {
//   margin-left: 10px;
// }
</style>
